import React from 'react'
import Helmet from 'react-helmet'
import { Layout, Heading, Row, Column, Image, Section, Text } from 'components'
import Blog from 'images/blogs/blog-6.png'
import 'components/css/stories.css'
import HostName from '../../data/static/HostNames'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/stories/why-digipay`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: '5 Reasons Why Digipay Is Important',
  },
  {
    property: 'og:description',
    content: 'Here are 5 reasons why you should try Digipay now!',
  },
  {
    property: 'og:image',
    content: Blog,
  },
]

const ReasonsWhy = () => (
  <Layout>
    <Helmet title="5 Reasons Why Digipay Is Important" meta={SITE_META}>
      <html lang="en" />
    </Helmet>

    <Row>
      <Column size="df:[100%] lg:[col-6]" py="sp4" px="sp3" m="auto">
        <Image width="100%" mb="sp3" src={Blog} />
        <Heading mb="sp4" fontWeight="bold" textAlign="center">
          5 REASONS WHY DIGIPAY IS IMPORTANT
        </Heading>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            MORE IPON, MORE FUN
          </Heading>
          <Text>
            Gusto mo ba ng dagdag kita sa wallet para makabili ng bagong gadget?
            Magagawa mo na 'yan with Digipay! Nasa bahay ka man o sa tindahan,
            kayang-kaya mo nang kumita ng extra. Sa bawat transaction, may
            makukuha kang rebate at may referral fee din sa bawat bagong Digipay
            Agent na mare-refer mo. Mas pinadali pa ang sign up process dahil
            pwede na itong gawin diretso sa website, i-click lang ang{' '}
            <a
              href={`https://${process.env.GATSBY_REACT_APP_HOSTNAME}/sign-up`}
            >
              https://www.digipay.ph/sign-up
            </a>
            . Marami talagang paraan kung paano mag-ipon gamit ang Digipay kaya
            naman sulit na sulit ang pag-invest dito!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            HASSLE-FREE
          </Heading>
          <Text>
            Bayaran na naman ba ng bills pero tag-ulan na at baha sa labas?
            Hindi mo na kailangang lumabas ng bahay para makapagbayad ng mga
            bills. Ilang tap na lang sa cellphone o click sa computer mo at
            tapos ka na sa mga bayarin mo. Mabilis dahil online lang ang lahat
            ng transactions, madali dahil hindi ka na mata-traffic sa daan, at
            mura dahil P100 lang ang sign up fee para maging Digipay Agent. What
            more can you ask for di ‘ba?
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            ONE-STOP-DIGITINDAHAN
          </Heading>
          <Text>
            Hindi lang pagkain, sabong panlaba, o inumin ang mabebenta mo sa
            iyong tindahan kapag nag-upgrade ka to a digitindahan. With Digipay,
            maaari ka nang mag-transact ng e-load katulad ng Globe, Smart, Talk
            N’ Text, o TM. Meron ding bills payment gaya ng Meralco, Maynilad,
            Manila Water, Home Credit, Cashalo at iba pa. Sa pera padala naman
            ay may GCash, PayMaya at Smart Money. Maaari ka ding bumili ng
            gaming pins at microinsurance. Ano pang hinihintay mo? Maging
            Ka-Digipay na!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            AS EASY AS ONE, TWO, THREE
          </Heading>
          <Text>
            Gamit lang ang iyong mobile phone at internet, magkakaroon ka na ng
            access sa daan-daang billers gamit ang Digipay app. Gusto mo
            magbayad ng Meralco? O kaya naman magpa-load? Paano naman kung
            kailangan mong magbayad ng credit card bill? O magpadala ng pera sa
            iyong pamilya? You can do all that with our app. Just swipe to pay,
            Ka-Digipay!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            PAPERLESS IS THE BEST
          </Heading>
          <Text>
            Imagine saving a lot of trees? Ganyan ang paraan ng Digipay upang
            makatulong sa sustainability ng mundo using paperless transactions.
            Ang lahat ng resibo ng bawat transaction mula sa Digipay app ay
            maaaring i-send via email o SMS. Maaari ring i-screenshot ang
            transaction receipt at i-send online. Sa mga paraang ito,
            mababawasan ang mga sayang na papel na maaari pang magamit sa ibang
            bagay. Truly, paperless is the best!
          </Text>
        </Section>
      </Column>
    </Row>
  </Layout>
)

export default ReasonsWhy
